<template>
  <form
    name="from"
    ref="from"
    action="https://sis-t.redsys.es:25443/sis/realizarPago"
    method="POST"
    target="_blank"
  >
    <input
      type="hidden"
      v-for="key in Object.keys(formData)"
      :name="key"
      :key="key"
      v-model="formData[key]"
    />
  </form>
</template>

<script>
import { checkPaymentRoutesName } from "@/router/names";

export default {
  name: "FormRedsys",
  props: { formData: { type: Object, required: true } },
  mounted() {
    this.$refs.from.submit();
    this.$router.replace({
      name: checkPaymentRoutesName.INDEX.name,
      params: { requestId: this.formData.request_id },
    });
  },
};
</script>
